<template>
  <v-container>
    <div class="about">
      <h2>About</h2>
      <v-card>
        <v-card-title>
          <span>WikiCode 在线学习网站毕业设计</span>
        </v-card-title>
        <v-card-subtitle>
          <span>视觉艺术学院数字媒体艺术设计专业2021届毕业设计作品</span>
        </v-card-subtitle>
        <v-card-text>
          <span>作者：桂旭华 | 指导老师：倪栋</span>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "About",
  mounted() {
    this.$store.state.APP_BAR.showBg = true;
    this.$store.state.APP_BAR.showSearch = false;
  },
};
</script>

<style lang="scss" scoped>
.about {
  width: 100%;
  padding-top: 20vh;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
}
</style>